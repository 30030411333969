import { Controller } from "stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = [ 'form', 'content', 'input', 'result', 'loading' ]

  connect() {
    useClickOutside(this)
  }

  clickOutside(event) {
    if (event.target.id !== 'show_all_link') {
      this.closeSearch()
    }
  }

  async search(ev) {
    ev.preventDefault()

    if (ev.key === 'Enter') {
      let path = this.getUrl(ev.target.value, true)

      if (ev.target.parentElement.dataset.searchId !== undefined) {
        path += `&search_id=${ev.target.parentElement.dataset.searchId}`
      }

      window.location.href = path
    }
    else {
      if (ev.key === 'Escape') {
        return this.closeSearch(ev)
      }

      this.contentTarget.innerHTML = this.getLoadingHTML()

      clearTimeout(this.contentTarget.dataset.timer)

      this.contentTarget.dataset.timer = setTimeout(() => {
        this.fetchSearch(ev.target.value)
      }, 500)
    }
  }

  async searchAll(ev) {
    ev.preventDefault()

    this.contentTarget.innerHTML = this.getLoadingHTML()

    await this.fetchSearch(this.inputTarget.value, true)
  }

  showSearchBox() {
    this.contentTarget.style.display = 'block'
    const body = document.getElementsByTagName("BODY")[0];
    body.classList.add('dropdown-opened')

    this.contentTarget.innerHTML = this.getLoadingHTML()

    this.fetchSearch(this.inputTarget.value)
  }

  async fetchSearch(searchText = '', getAll = false) {
    const url = this.getUrl(searchText, getAll)

    const response =  await fetch(url)

    const responseHTML = await response.text()
    if (response.url.includes('users/sign_in')) {
      window.location = response.url
    }

    this.contentTarget.innerHTML = responseHTML
  }

  closeSearch() {
    this.inputTarget.blur()

    this.contentTarget.style.display = 'none'

    this.contentTarget.innerHTML = ""

    const body = document.getElementsByTagName("BODY")[0];
    body.classList.remove('dropdown-opened')
  }

  getLoadingHTML() {
    return `<div class='overlay'></div>
            <div class='portal portal--search'>
              <div class='search__result'>
                <h3 class='search__result-title'>Loading...</h3>
              </div>
            </div>`
  }

  getUrl(searchText = '', getAll = false) {
    let params = new URLSearchParams()
    params.append('q', searchText)
    params.append('get_all', getAll)
    return `${this.formTarget.action}?${params}`
  }
}
