import { Controller} from "stimulus";
import {useClickOutside} from "stimulus-use";

export default class extends Controller {
  connect() {
    this.waitForElementAndScroll();
  }

  waitForElementAndScroll() {
    const targetId = window.location.hash.replace('#', '');
    if (!targetId) return;

    const observer = new MutationObserver((mutations, obs) => {
      const element = document.getElementById(targetId);
      if (element) {
        const offset = document.querySelector('#bio-top-menu')?.offsetHeight || 0;
        element.style.scrollMarginTop = `${offset}px`;

        element.scrollIntoView({ behavior: "smooth", block: "start" });
        obs.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
  }
}
